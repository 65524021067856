// eslint-disable-next-line no-undef
module.exports = {
  title: `Blue House Technologies`,
  description: `Blue House Technologies`,
  author: `Robert Rochon`,
  introduction: `Blue House Technologies`,
  siteUrl: `https://bluehouse.tech/`,
  social: {
    twitter: ``, // Your Twitter account
    github: ``, // Your GitHub account
    medium: ``, // Your Medium account
    facebook: ``, // Your Facebook account
    linkedin: ``, // Your LinkedIn account
  },
  icon: `static/favicon.png`,
  keywords: [`blog`],
  share: {
    facebookAppId: '', // Add facebookAppId for using facebook share feature v3.2
  },
  ga: 'UA-146112828-1', // Add your google analytics tranking ID
};
